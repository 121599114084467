<template>
    <main class="main">

            <nav aria-label="breadcrumb" class="breadcrumb-nav">
                <div class="container">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Shopping Cart</li>
                    </ol>
                </div>
            </nav>

            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
							<div class="cart-table-container">
								<table class="table table-cart">
									<thead>
										<tr>
											<th class="product-col">Product</th>
											<th class="price-col">Price</th>
											<th class="qty-col">Qty</th>
											<th>Subtotal</th>
										</tr>
									</thead>
									<tbody>
										<tr class="product-row" v-for="cartItems in cart" :key="cartItems.id">
											<td class="product-col">
												<figure class="product-image-container">
													<a href="product.html" class="product-image">
														<img :src="base_url+'/api/public/file/download/'+cartItems.product.imageUrl" alt="product">
													</a>
												</figure>
												<h2 class="product-title">
													<a href="product.html">{{ cartItems.productName }}</a>
												</h2>
											</td>
											<td>{{ cartItems.product.price }}</td>
											<td>
												<div class="">
                                                    <input class="" v-on:keyup.page-up="changeQuantity(cartItems.id, cartItems.quantity)" v-model="cartItems.quantity" type="number"/>
                                                </div>
											</td>
											<td>{{ cartItems.quantity * cartItems.product.price }}</td>
                                            <td><a href=""><i class="icon-pencil"></i> تعديل</a></td>
										</tr>
										<!-- <tr class="product-action-row">
											<td colspan="4" class="clearfix">
												<div class="float-left">
													<a href="#" class="btn-move">Move to Wishlist</a>
												</div>

												<div class="float-right">
													<a href="#" title="Edit product" class="btn-edit"><span class="sr-only">Edit</span><i class="icon-pencil"></i></a>
													<a href="#" title="Remove product" class="btn-remove"><span class="sr-only">Remove</span></a>
												</div>
											</td>
										</tr> -->

									</tbody>

									<tfoot>
										<tr>
											<td colspan="4" class="clearfix">
												<div class="float-left">
													<a href="category.html" class="btn btn-outline-secondary">Continue Shopping</a>
												</div><!-- End .float-left -->

												<div class="float-right">
													<a href="#" class="btn btn-outline-secondary btn-clear-cart">Clear Shopping Cart</a>
													<a href="#" class="btn btn-outline-secondary btn-update-cart">Update Shopping Cart</a>
												</div><!-- End .float-right -->
											</td>
										</tr>
									</tfoot>
								</table>
							</div><!-- End .cart-table-container -->

							<div class="cart-discount">
								<h4>Apply Discount Code</h4>
								<form action="#">
									<div class="input-group">
										<input type="text" class="form-control form-control-sm" placeholder="Enter discount code" required="">
										<div class="input-group-append">
											<button class="btn btn-sm btn-primary" type="submit">Apply Discount</button>
										</div>
									</div><!-- End .input-group -->
								</form>
							</div><!-- End .cart-discount -->
						</div>

                    <div class="col-lg-4">
                        <div class="cart-summary">
                            <h3>Summary</h3>

                           

                            

                            <table class="table table-totals">
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>$17.90</td>
                                    </tr>

                                    <tr>
                                        <td>Tax</td>
                                        <td>$0.00</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Order Total</td>
                                        <td>$17.90</td>
                                    </tr>
                                </tfoot>
                            </table>

                            <div class="checkout-methods">
                                <a href="checkout-shipping.html" class="btn btn-block btn-sm btn-primary">Go to Checkout</a>
                                <a href="#" class="btn btn-link btn-block">Check Out with Multiple Addresses</a>
                            </div><!-- End .checkout-methods -->
                        </div><!-- End .cart-summary -->
                    </div><!-- End .col-lg-4 -->
                </div><!-- End .row -->
            </div><!-- End .container -->
        </main>
</template>
<style scoped>
main {
    background-color: #ffffff;
}
.product-title {
    width: 536px;
    overflow: hidden;
}
</style>


<script> 
 
export default { 
    data() { 
        return { 
            total: 0, 
            cart: [], 
            countProducts   : 0, 
            currentPage : 0, 
            page : 1, 
            firstPage   : 0, 
            LastPage    : 0, 
            nextPage    : 0, 
            previosPage : 0, 
            base_url: process.env.VUE_APP_URL, 
        } 
    }, 
    mounted() { 
        this.getCartProducts(); 
    }, 
    methods: { 
        getCartProducts() { 
            this.axios 
            .get(process.env.VUE_APP_URL+'/api/cart-items?page=0&size=100000',{ 
                headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('token') 
            }}) 
            .then(response => { 
                this.total = 0
                this.cart = response.data  
                console.log(this.cart)
            }).catch(err =>{
                console.log(err)
            })
        }, 
        total_price(cart, price) { 
            let quantity = document.getElementById('quantity_'+cart.product.id).value;
            console.log(price)
            document.getElementById('total_price_'+cart.product.id).style.display = "none";
            document.getElementById('spinner'+cart.product.id).style.display = "block";
            if (quantity > cart.product.stockQuantity) { 
                document.getElementById('quantity_'+cart.product.id).value = 2
            } else if (quantity > 0 && quantity <= cart.product.stockQuantity) { 
                this.changeQuantity(cart, quantity);
            } 
            this.getCartProducts();
            document.getElementById('total_price_'+cart.product.id).style.display = "block";
            document.getElementById('spinner'+cart.product.id).style.display = "none";
        }, 
        changeQuantity(cartId, Quantity) { 
            this.axios 
            .put(process.env.VUE_APP_URL+'/api/cart-items/'+cartId+'?quantity='+Quantity,{ 
                headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('token') 
            }}) 
            .then(response => { 
                console.log(response)
            }).catch(err =>{
                console.log(err)
            })
        }, 
        // changeQuantity: function(cart, quantity) { 
        //     this.axios 
        //     .put('http://ezadhss.elzad.ly/api/cart-items', { 
        //             customerId: localStorage.getItem('customer_id'), 
        //             id: cart.id, 
        //             product: cart.product, 
        //             productId: cart.product.id, 
        //             productName: cart.product.name, 
        //             quantity: quantity 
        //         }, { 
        //         headers: { 
        //             'Authorization': 'Bearer '+localStorage.getItem('token') 
        //         } 
        //     }) 
        // }, 
        // delete_cart: function(id) { 
        //     this.axios 
        //         .delete(process.env.VUE_APP_URL+`/api/cart-items/${id}`, { 
        //             headers: { 
        //                 'Authorization': 'Bearer ' + localStorage.getItem('token') 
        //             } 
        //         }) 
        //         .then(() => { 
        //         localStorage.setItem('cartCount', localStorage.getItem('cartCount')-1)  
        //         this.getCartProducts() 
        //         }) 
        // }, 
        // setPage: function(page) { 
        //     page = page-1; 
        //     this.axios 
        //     .get(process.env.VUE_APP_URL+'/api/cart-items?page='+page+'&size=100000',{ 
        //         headers: { 
        //         'Access-Control-Allow-Origin': '*' 
        //         } 
        //     }) 
        //     .then(response => { 
        //         this.products = response 
        //     }) 
        // }, 
    }, 
} 
</script> 